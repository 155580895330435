var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-price pt-3 d-flex flex-row flex-start"},[(_vm.product.priceDisplay)?[(
        !this.isPackage ||
          this.isPackage == 'false' ||
          this.product.priceStandardDisplay ||
          !_vm.product.priceStock1Display
      )?_c('div',{staticClass:"price-box px-2 mr-1",class:_vm.cssClass},[_c('span',{staticClass:"box-label",domProps:{"innerHTML":_vm._s(_vm.header)}}),_c('div',{staticClass:"old-price"},[(_vm.product.priceStandardDisplay)?_c('span',[_vm._v(_vm._s(_vm.$n(_vm.product.priceStandardDisplay, "currency")))]):_vm._e()]),_c('div',{staticClass:"cur-price",class:{ 'promo-price': _vm.product.priceStandardDisplay },attrs:{"itemprop":"price"}},[_vm._v(" "+_vm._s(_vm.$n(_vm.product.priceDisplay, "currency"))+" "),(!_vm.isPezzo)?_c('span',{staticClass:"weight-unit"},[_vm._v("/"+_vm._s(_vm.product.weightUnitDisplay))]):_c('span',{staticClass:"weight-unit"},[_vm._v("/pz")])]),_c('div',{staticClass:"product-descr-weight"},[(_vm.isPezzo && _vm.product.priceUmDisplay)?_c('span',{staticClass:"weight-unit"},[_vm._v(" "+_vm._s(_vm.$n(_vm.product.priceUmDisplay, "currency"))+"/"+_vm._s(_vm.product.weightUnitDisplay)+" ")]):_vm._e()])]):_vm._e(),(
        (!this.isPackage || this.isPackage == 'true') &&
          _vm.product.priceStock1Display &&
          _vm.product.priceStock1Display !== _vm.product.priceDisplay &&
          _vm.product.warehousePromo.warehousePromoTypeId == -1 &&
          _vm.product.productInfos.QTY_MULT > 1
      )?_c('div',{staticClass:"price-box promo_price_stock px-2 mr-1"},[_c('span',{staticClass:"box-label"},[_vm._v("Promo collo")]),_c('div',{staticClass:"promo-header"},[_vm._v(" "+_vm._s(_vm.product.productInfos.LOGISTIC_PACKAGING_ITEMS)+" pz "+_vm._s(_vm.$n( _vm.product.productInfos.LOGISTIC_PACKAGING_ITEMS * _vm.product.priceStock1Display, "currency" ))+" ")]),_c('div',{staticClass:"promo-body",attrs:{"itemprop":"price"}},[_vm._v(" "+_vm._s(_vm.$n(_vm.product.priceStock1Display, "currency"))+" "),(!_vm.isPezzo)?_c('span',{staticClass:"weight-unit"},[_vm._v("/"+_vm._s(_vm.product.weightUnitDisplay))]):_c('span',{staticClass:"weight-unit"},[_vm._v("/pz")])]),_c('div',{staticClass:"product-descr-weight"},[(_vm.isPezzo && _vm.product.priceUmDisplay)?_c('span',{staticClass:"weight-unit"},[_vm._v(" "+_vm._s(_vm.$n(_vm.packagePricePerUnit, "currency"))+"/"+_vm._s(_vm.product.weightUnitDisplay)+" ")]):_vm._e()])]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }