import BannerGrid from "./BannerGrid.vue";
import BannerSlider from "./BannerSlider.vue";
import CrmProductSlider from "./CrmProductSlider.vue";
import ProductSlider from "./ProductSlider.vue";
import StaticText from "./StaticText.vue";
import * as categoryBlockHelper from "@/components/categoryBlock/categoryBlockHelper";

import kebabCase from "lodash/kebabCase";
import get from "lodash/get";

export default {
  install: app => {
    app.component("category-block", {
      functional: true,
      name: "category-block",
      props: {
        target: {
          type: Object,
          required: false
        },
        position: {
          type: String,
          required: false
        },
        config: {
          type: Object,
          required: false
        },
        container: {
          type: Boolean,
          default: true
        },
        productId: {
          type: Number,
          required: false
        },
        categoryId: {
          type: Number,
          required: false
        }
      },
      render: function(createElement, context) {
        let cfg;
        if (context.props.config) {
          cfg = context.props.config;
        } else if (context.props.position) {
          cfg = context.props.target?.categoryBlocks?.find(
            categoryBlock =>
              categoryBlock.templateBlock.codInt == context.props.position
          );
        } else {
          cfg = context.props.target?.categoryBlocks?.[0];
        }
        if (cfg) {
          // const cmp = defineAsyncComponent({
          //   loader: () => import("@/components/categoryBlock/BannerSlider.vue")
          // });
          let cmp;

          //   = () =>
          //     import(
          //       `@/components/categoryBlock/${cfg.templateBlockType.modeName}.vue`
          //     );
          switch (cfg.templateBlockType.modeName) {
            case "BannerGrid":
              cmp = BannerGrid;
              break;
            case "BannerSlider":
              cmp = BannerSlider;
              break;
            case "CrmProductSlider":
              cmp = CrmProductSlider;
              break;
            case "ProductSlider":
              cmp = ProductSlider;
              break;
            case "StaticText":
              cmp = StaticText;
              break;
          }

          if (cmp) {
            const id = `category-block-${cfg.categoryBlockId}`;
            const componentName = kebabCase(cfg.templateBlockType.modeName);
            let content = [];
            let style = app.$ebsn.meta(
              cfg,
              `categoryblocktype_${cfg.templateBlockType.modeName}.CSS_STYLE`
            );
            let minHeightStyle = categoryBlockHelper.calcMinHeightStyle(
              app.$ebsn.meta(
                cfg,
                `categoryblocktype_${cfg.templateBlockType.modeName}.MIN_HEIGHTS`
              ),
              id
            );
            if (style || minHeightStyle) {
              let value = "";
              if (style) {
                value = style.replace(
                  /([^\r\n,{};(?!@)]+)(,|{)/g,
                  `#${id}` + " " + "$1$2"
                );
              }
              content.push(
                createElement(
                  "style",
                  {
                    attrs: {
                      type: "text/css"
                    }
                  },
                  value + " " + minHeightStyle ? minHeightStyle : " "
                )
              );
            }

            let attrs = {};
            if (context.data.attrs.style)
              attrs.style = context.data.attrs.style;
            if (context.data.attrs.class)
              attrs.class = context.data.attrs.class;

            content.push(
              createElement(cmp, {
                props: {
                  id: id,
                  config: cfg,
                  container: context.props.container,
                  productId: context.props.productId,
                  categoryId: context.props.categoryId
                },
                staticClass: ` ${context.data.staticClass} ${context.data.class} `,
                attrs: attrs
                // style: createStyle(cfg, cfg.templateBlockType.modeName)
              })
            );
            if (context.props.container) {
              content = createElement("div", { staticClass: "container" }, [
                content
              ]);
            }
            return createElement(
              "div",
              {
                attrs: {
                  id: id
                },
                staticClass: ` ${componentName} ${id} `,
                on: {
                  hide: function(event, a) {
                    console.log(event);
                    console.log(a);
                    if (!context.data.style) {
                      context.data.style = { display: "none" };
                    } else {
                      context.data.style.display = "none";
                    }
                  }
                },
                style: createStyle(cfg, cfg.templateBlockType.modeName)
              },
              [content]
            );
            // return createElement(cmp, {
            //   props: {
            //     id: id,
            //     config: cfg,
            //     container: context.props.container,
            //     productId: context.props.productId,
            //     categoryId: context.props.categoryId
            //   },
            //   staticClass: context.data.staticClass
            // });
          }
        }
        return null;
        function createStyle(config, optionsName) {
          let style = {};
          let backgroundColor = get(
            config,
            `metaData.categoryblocktype_${optionsName}.BACKGROUND_COLOR`
          );
          if (backgroundColor) {
            style.backgroundColor = backgroundColor;
          }
          return style;
        }
      }
    });
  }
};
