<template>
  <v-hover v-slot="{ hover }">
    <v-card
      rounded="lg"
      :href="link"
      outlined
      @click.prevent="handleLink"
      class="proposal-btn"
      :class="{ 'is-hovering': hover }"
    >
      <div class="img-container rounded-lg">
        <img
          :src="proposal.img"
          :alt="proposal.imgAlt"
          :title="proposal.imgDescription"
          class="proposal-btn-img"
        />
      </div>
      <div
        class="btn-label"
        v-if="proposal.descr"
        v-html="proposal.descr"
      ></div>
    </v-card>
  </v-hover>
</template>
<style lang="scss">
svg {
  fill: inherit;
}
path {
  fill: inherit;
}
.proposal-btn {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-color: white;
  .img-container {
    text-align: center;
    padding: 8px;
    .proposal-btn-img {
      width: 40px;
      height: auto;
      max-height: 40px;
    }
  }
  &.is-hovering {
    transition: 0.15s all ease-in-out;
    &.proposal-btn {
      background-color: var(--v-grey-lighten2);
    }
  }
  .btn-label {
    color: var(--v-primary-lighten1);
    padding: 8px;
    overflow: hidden;
    font-weight: 700;
    letter-spacing: 0;
    font-size: 1rem;
    max-width: 100%;
    text-align: left;
    @media (max-width: 320px) {
      white-space: normal;
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    flex-direction: column;
    .img-container {
      padding-bottom: 0px;
    }
    .btn-label {
      text-align: center;
    }
  }
}
</style>
<script>
import banner from "~/mixins/banner";

export default {
  name: "ProposalButton",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {},
  methods: {}
};
</script>
