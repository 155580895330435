function calcMinHeightStyle(minHeightsObj, catBlockId) {
  try {
    let breakspoints = {
      xs: { minWidth: 0, maxWidth: 599 },
      sm: { minWidth: 600, maxWidth: 959 },
      md: { minWidth: 960, maxWidth: 1263 },
      lg: { minWidth: 1264, maxWidth: 1903 },
      xl: { minWidth: 1904 }
    };
    if (minHeightsObj) {
      let minHeightStyle = Object.keys(minHeightsObj).reduce(
        (cumulativeString, key) => {
          let breakpoint = breakspoints[key];
          let minHeight = minHeightsObj[key];
          let breakpointMinHeightCss = "";
          if (minHeight && minHeight > 0) {
            breakpointMinHeightCss = breakpoint.maxWidth
              ? `@media screen and (min-width: ${breakpoint.minWidth}px) and (max-width: ${breakpoint.maxWidth}px){ #${catBlockId} {min-height: ${minHeight}px} }
                  `
              : `@media screen and (min-width: ${breakpoint.minWidth}px){ #${catBlockId} {min-height: ${minHeight}px} }
                  `;
          }
          return cumulativeString + breakpointMinHeightCss;
        },
        ""
      );
      return minHeightStyle;
    } else {
      return null;
    }
  } catch (err) {
    console.log("calcMinHeightStyle", err);
    return null;
  }
}

export { calcMinHeightStyle };
