import { req, len } from "./commons";

function requiredValue(error) {
  var errorMessage = error ? error : "Campo obbligatorio";
  return function(v) {
    return !!v || errorMessage;
  };
}
function isGenericPhone(error) {
  //(HomePhone | CellPhoneIta | CellPhoneCh)
  var errorMessage = error ? error : "Digitare un numero di telefono valido";
  return function(v) {
    return (
      /(^[0-9]{2,4}(\s)?[0-9]{3,6}$)|(^(([+]|00)\d{2})?([\s-])?(((3[0-9][0-9]))?(\d{7})|(\d{2})\s*(\d{3})\s*(\d{2})\s*(\d{2}))$)/i.test(
        v
      ) || errorMessage
    );
  };
}
function isMobilePhone(error) {
  // CellPhoneIta | CellPhoneCh
  var errorMessage = error ? error : "Digitare un numero di telefono valido";
  return function(v) {
    return (
      !req(v) ||
      /(^(([+]|00)\d{2})?([\s-])?(((3[0-9][0-9]))?(\d{7})|(\d{2})\s*(\d{3})\s*(\d{2})\s*(\d{2}))$)/i.test(
        v
      ) ||
      errorMessage
    );
  };
}
function isHomePhone(error) {
  //(HomePhone
  var errorMessage = error ? error : "Digitare un numero di telefono valido";
  return function(v) {
    return !req(v) || /(^[0-9]{2,4}(\s)?[0-9]{3,6}$)/i.test(v) || errorMessage;
  };
}
function isCAP(error) {
  var errorMessage = error ? error : "Il CAP deve essere di 4 o 5 cifre";
  return function(v) {
    return /^[0-9]{4,5}$/.test(v) || errorMessage;
  };
}
function isIstatCode(error) {
  var errorMessage = error ? error : "Lo ZIP Code deve avere 10 cifre";
  return function(v) {
    return /^[0-9]{10}$/.test(v) || errorMessage;
  };
}
function isPasswordMinimumLength(error) {
  var errorMessage = error
    ? error
    : "La password deve essere lunga 6 caratteri";
  return function(v) {
    return /.{6,}/.test(v) || errorMessage;
  };
}
function isEmail(error) {
  var errorMessage = error ? error : "Digitare una e-mail valida";
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  //const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
  return function(v) {
    return !req(v) || emailRegex.test(v) || errorMessage;
  };
}
function isCF(error) {
  var errorMessage = error ? error : "Formato non corretto";
  return function(v) {
    return (
      !req(v) ||
      /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/.test(
        v.toUpperCase()
      ) ||
      errorMessage
    );
  };
}
function isCfIva(error) {
  var errorMessage = error ? error : "P.Iva e C.F. devono avere 11 cifre";
  return function(v) {
    return !req(v) || /^[0-9]{11}$/.test(v) || errorMessage;
  };
}
function isIvaMalta(error) {
  var errorMessage = error
    ? error
    : "La p. Iva deve essere composta da MT seguito da 8 cifre";
  return function(v) {
    return !req(v) || /^(MT)?[0-9]{8}$/.test(v) || errorMessage;
  };
}
function isIvaItalia(error) {
  var errorMessage = error
    ? error
    : "La p. Iva deve essere composta da IT seguito da 11 cifre";
  return function(v) {
    return !req(v) || /^(IT)?[0-9]{11}$/.test(v) || errorMessage;
  };
}
function isIvaGermania(error) {
  var errorMessage = error
    ? error
    : "La p. Iva deve essere composta da DE seguito da 9 cifre";
  return function(v) {
    return !req(v) || /^(DE)?[0-9]{9}$/.test(v) || errorMessage;
  };
}
function isIvaFrancia(error) {
  var errorMessage = error
    ? error
    : "La p. Iva deve essere composta da FR seguito da 11 cifre";
  let vatexp = [];
  vatexp.push(/^(FR)(\d{11})$/);
  vatexp.push(/^(FR)([A-HJ-NP-Z]\d{10})$/);
  vatexp.push(/^(FR)(\d[A-HJ-NP-Z]\d{9})$/);
  vatexp.push(/^(FR)([A-HJ-NP-Z]{2}\d{9})$/);

  return function(v) {
    // Load up the string to check
    // v = v.toUpperCase();
    // Remove spaces etc. from the VAT number to help validation
    return !req(v) || checkVATNumber(vatexp, v, "FR") || errorMessage;
  };
}

function checkVATNumber(vatexp, VATNumber, defCCode) {
  // Assume we're not going to find a valid VAT number
  var valid = false;

  VATNumber = VATNumber.toUpperCase();
  VATNumber = VATNumber.replace(/(\s|-|\.)+/g, "");

  // Check the string against the regular expressions for all types of VAT numbers
  for (var i = 0; i < vatexp.length; i++) {
    // Have we recognised the VAT number?
    if (vatexp[i].test(VATNumber)) {
      // Yes - we have
      var cNumber = VATNumber.substring(2); // Isolate the number

      if (defCCode === "FR") {
        valid = FRVATCheckDigit(cNumber);
      }

      // Having processed the number, we break from the loop
      break;
    }
  }

  // Return with either an error or the reformatted VAT number
  return valid;
}

function FRVATCheckDigit(vatnumber) {
  // Checks the check digits of a French VAT number.

  if (!/^\d{11}$/.test(vatnumber)) return true;

  // Extract the last nine digits as an integer.
  var total = vatnumber.substring(2);

  // Establish check digit.
  total = (total * 100 + 12) % 97;

  // Compare it with the last character of the VAT number. If it's the same, then it's valid.
  if (total == vatnumber.slice(0, 2)) return true;
  else return false;
}

function isMaltaPostalCode(error) {
  var errorMessage = error ? error : "Formato non valido";
  return function(v) {
    return !req(v) || /^[A-Za-z]{3}?[0-9]{2}$/.test(v) || errorMessage;
  };
}
function isSDICode(error) {
  var errorMessage = error ? error : "Codice SDI non valido";
  return function(v) {
    return !req(v) || /^[A-Z0-9]{6,7}$/.test(v) || errorMessage;
  };
}
function isDate(error) {
  var errorMessage = error ? error : "Formato della data gg/mm/aaaa";
  return function(v) {
    return (
      !req(v) ||
      /^(0[1-9]|1[012])[- /.] (0[1-9]|[12][0-9]|3[01])[- /.] (19|20)\d\d$/.test(
        v
      ) ||
      errorMessage
    );
  };
}
function isAlpha(error) {
  //Accepts only alphabet characters.
  var errorMessage = error ? error : "Accetta solo caratteri dell'alfabeto";
  return function(v) {
    return !req(v) || /^[a-zA-Z]*$/.test(v) || errorMessage;
  };
}
function isAlphaNum(error) {
  //Accepts only alphanumerics.
  var errorMessage = error ? error : "Accetta solo caratteri alfanumerici";
  return function(v) {
    return !req(v) || /^[a-zA-Z0-9]*$/.test(v) || errorMessage;
  };
}
function isInteger(error) {
  var errorMessage = error ? error : "Accetta solo numeri interi";
  return function(v) {
    return !req(v) || /(^[0-9]*$)|(^-[0-9]+$)/.test(v) || errorMessage;
  };
}
function isNumber(error) {
  var errorMessage = error ? error : "Accetta solo numeri";
  return function(v) {
    return !req(v) || /^[0-9]*$/.test(v) || errorMessage;
  };
}
function isLength(length, error) {
  var errorMessage = error
    ? error
    : "Il campo deve essere lungo " + length + "caratteri";
  var l = length;
  return function(v) {
    return !req(v) || len(v) == l || errorMessage;
  };
}
function minLength(length, error) {
  var errorMessage = error ? error : "Lunghezza minima" + length + "caratteri";
  var l = length;
  return function(v) {
    return !req(v) || len(v) >= l || errorMessage;
  };
}
function maxLength(length, error) {
  var errorMessage = error ? error : "Lunghezza massima" + length + "caratteri";
  var l = length;
  return function(v) {
    return !req(v) || len(v) <= l || errorMessage;
  };
}
function isUrl(error) {
  var errorMessage = error ? error : "Digitare un url valido";
  return function(v) {
    return (
      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        v
      ) || errorMessage
    );
  };
}
function isAddressNumber(error) {
  var errorMessage = error ? error : "Inserisci numeri o SNC";
  return function(v) {
    return (
      !req(v) ||
      /(^[0-9]{1,3}([a-zA-Z]){0,1})$|(^-[0-9]+$)|(^snc$)|(^SNC$)|(^s.n.c$)|(^S.N.C$)|(^s.n.c.$)|(^S.N.C.$)/.test(
        v
      ) ||
      errorMessage
    );
  };
}

export {
  requiredValue,
  isGenericPhone,
  isMobilePhone,
  isHomePhone,
  isCAP,
  isIstatCode,
  isPasswordMinimumLength,
  isEmail,
  isCF,
  isCfIva,
  isDate,
  isSDICode,
  isAlpha,
  isAlphaNum,
  isInteger,
  isNumber,
  isUrl,
  isLength,
  minLength,
  maxLength,
  isIvaItalia,
  isIvaMalta,
  isIvaGermania,
  isIvaFrancia,
  isMaltaPostalCode,
  isAddressNumber
};
