<template>
  <div class="product-price pt-3 d-flex flex-row flex-start">
    <template v-if="product.priceDisplay">
      <div
        class="price-box px-2 mr-1"
        :class="cssClass"
        v-if="
          !this.isPackage ||
            this.isPackage == 'false' ||
            this.product.priceStandardDisplay ||
            !product.priceStock1Display
        "
      >
        <span class="box-label" v-html="header"></span>
        <div class="old-price">
          <span v-if="product.priceStandardDisplay">{{
            $n(product.priceStandardDisplay, "currency")
          }}</span>
        </div>
        <div
          class="cur-price"
          :class="{ 'promo-price': product.priceStandardDisplay }"
          itemprop="price"
        >
          {{ $n(product.priceDisplay, "currency") }}
          <span class="weight-unit" v-if="!isPezzo"
            >/{{ product.weightUnitDisplay }}</span
          >
          <span class="weight-unit" v-else>/pz</span>
        </div>
        <div class="product-descr-weight">
          <span class="weight-unit" v-if="isPezzo && product.priceUmDisplay">
            {{ $n(product.priceUmDisplay, "currency") }}/{{
              product.weightUnitDisplay
            }}
          </span>
        </div>
      </div>
      <div
        v-if="
          (!this.isPackage || this.isPackage == 'true') &&
            product.priceStock1Display &&
            product.priceStock1Display !== product.priceDisplay &&
            product.warehousePromo.warehousePromoTypeId == -1 &&
            product.productInfos.QTY_MULT > 1
        "
        class="price-box promo_price_stock px-2 mr-1"
      >
        <span class="box-label">Promo collo</span>
        <div class="promo-header">
          {{ product.productInfos.LOGISTIC_PACKAGING_ITEMS }} pz
          {{
            $n(
              product.productInfos.LOGISTIC_PACKAGING_ITEMS *
                product.priceStock1Display,
              "currency"
            )
          }}
        </div>
        <div class="promo-body" itemprop="price">
          {{ $n(product.priceStock1Display, "currency") }}
          <span class="weight-unit" v-if="!isPezzo"
            >/{{ product.weightUnitDisplay }}</span
          ><span class="weight-unit" v-else>/pz</span>
        </div>
        <div class="product-descr-weight">
          <span class="weight-unit" v-if="isPezzo && product.priceUmDisplay">
            {{ $n(packagePricePerUnit, "currency") }}/{{
              product.weightUnitDisplay
            }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import catalogService from "~/service/catalogService";
import get from "lodash/get";

export default {
  name: "ProductPrice",
  props: {
    product: {
      type: Object,
      required: true
    },
    isPackage: {
      type: String,
      required: false
    }
  },
  computed: {
    isPezzo: function() {
      return this.product.productInfos.TIPOLOGIA == "Pezzo";
    },
    packagePricePerUnit() {
      return catalogService.getPricePerUnit(
        this.product.priceStock1Display,
        this.product
      );
    },
    cssClass() {
      return get(this.product, "warehousePromo.view.cssClass");
    },
    header() {
      return get(this.product, "warehousePromo.view.header", "Prezzo");
    }
  }
};
</script>
