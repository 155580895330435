<template>
  <div class="qty-wrap rounded w-100" :class="{ 'not-empty': quantity > 0 }">
    <template v-if="!showQntyInput">
      <a
        role="button"
        class="minus"
        @click.stop.prevent="
          minus(true, { infos: { is_package: isPackage, ...selectedOptions } })
        "
        @mousedown.stop
      >
        <v-icon>mdi-minus</v-icon>
      </a>
      <div
        class="val-cnt"
        @click.stop.prevent=""
        @dblclick="isPackage == 'true' ? toggleShowInput() : () => {}"
        v-long-press="300"
        @long-press-start="isPackage == 'true' ? toggleShowInput() : () => {}"
        @mousedown.stop
      >
        <span class="val">{{ quantity }} {{ unit }}</span>
        <span class="small">{{ quantityPerUnit }}</span>
        <span class="package small" v-if="isPackage == 'true'">
          {{ packageCount }}
        </span>
      </div>
      <a
        role="button"
        class="plus"
        @click.stop.prevent="
          plus(true, { infos: { is_package: isPackage, ...selectedOptions } })
        "
        @mousedown.stop
      >
        <v-icon>mdi-plus</v-icon>
      </a>
    </template>
    <template
      v-else
      class="qnty-manual-input rounded-md"
      :class="{ 'not-empty': quantity > 0 }"
      transition="fab-transition"
    >
      <a
        role="button"
        class="close minus"
        @click.stop.prevent="toggleShowInput(false)"
        @mousedown.stop
      >
        <v-icon>mdi-close</v-icon>
      </a>
      <v-tooltip
        bottom
        :open-on-hover="false"
        :open-on-click="true"
        :value="showTooltip"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="qntyInput"
            ref="manualInput"
            class="manual-input"
            hide-details
            autofocus
            outlined
            dense
            type="number"
            @click.stop.prevent=""
            @keyup.enter.prevent="
              toggleShowInput(true, {
                infos: { is_package: isPackage, ...selectedOptions }
              })
            "
            @keyup.esc="toggleShowInput(false)"
            @mousedown.stop
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <span
          class="d-block qntity-tooltip"
          v-if="!modulus(qntyInput)"
          v-html="
            $t('product.quantity.confirmQntity', {
              qntyInput: this.qntyInput
            })
          "
        >
        </span>
        <span
          class="d-block qntity-tooltip"
          v-if="
            modulus(qntyInput) &&
              parseInt(qntyInput) <=
                parseInt(this.product.productInfos.QTY_MULT)
          "
          v-html="
            $t('product.quantity.editQntityMin', {
              lowerInput: this.lowerInput
            })
          "
        >
        </span>

        <span
          class="qntity-tooltip"
          v-if="
            modulus(qntyInput) &&
              parseInt(qntyInput) > parseInt(this.product.productInfos.QTY_MULT)
          "
          v-html="
            $t('product.quantity.editQntityMinMax', {
              lowerInput: this.lowerInput,
              higherInput: this.higherInput
            })
          "
        >
        </span>
      </v-tooltip>
      <a
        role="button"
        class="check plus"
        @click.stop.prevent="
          toggleShowInput(true, {
            infos: { is_package: isPackage, ...selectedOptions }
          })
        "
        @mousedown.stop
      >
        <v-icon>mdi-check</v-icon>
      </a>
    </template>
  </div>
</template>
<style lang="scss">
.v-tooltip__content {
  .qntity-tooltip {
    color: $text-color;
  }
}
.manual-input {
  .v-input__control {
    .v-input__slot {
      padding: 0px !important;
      fieldset {
        border: none;
        padding-left: 0px;
      }
      input {
        font-weight: 700;
        color: #515151;
      }
    }
  }
}
</style>
<script>
import productMixin from "~/mixins/product";

import LongPress from "vue-directive-long-press";
import { mask } from "vue-the-mask";

export default {
  name: "ProductCard",
  data() {
    return {
      showTooltip: true
    };
  },
  props: {
    product: { type: Object, required: true },
    item: { type: Object, required: false },
    isPackage: { type: String, default: "false" },
    updateCart: { type: Boolean, default: global.config.updateCart },
    selectedOptions: { type: Object, default: () => ({}) }
  },
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  // eslint-disable-next-line vue/no-unused-components
  mixins: [productMixin],
  computed: {
    packageCount() {
      if (this.product.productInfos.TIPOLOGIA != "Sfuso" && this.item) {
        const logisticPackagingItems =
          this.product.productInfos.LOGISTIC_PACKAGING_ITEMS ||
          this.product.logisticPackagingItems;
        if (
          this.product.logisticPalletItems > 0 &&
          this.item.quantity % this.product.logisticPalletItems == 0
        ) {
          return (
            this.item.quantity / this.product.logisticPalletItems + " pallet"
          );
        } else if (
          logisticPackagingItems > 0 &&
          this.item.quantity % logisticPackagingItems == 0
        ) {
          let unit = this.item.quantity / logisticPackagingItems;
          return "(" + unit + (unit == 1 ? " collo" : " colli") + ")";
        }
      }
      return "(0 colli)";
    }
  },
  watch: {
    qntyInput(val, oldVal) {
      if (val !== oldVal && val === "") {
        this.showTooltip = false;
      } else {
        this.showTooltip = true;
      }
    }
  }
};
</script>
