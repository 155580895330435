<template>
  <v-card
    :aria-label="`Vai a ${proposal.name}`"
    rounded="md"
    :href="link"
    @click.prevent="handleLink"
    elevation="0"
    class="proposal-img"
    v-intersect.once="handleView"
  >
    <v-img
      eager
      :src="src"
      :alt="proposal.name"
      :id="`proposal-image-${proposal.id}`"
    >
      <div
        v-if="proposal.content"
        class="overlay"
        v-html="proposal.content"
      ></div>
    </v-img>
  </v-card>
</template>
<script>
import get from "lodash/get";
import banner from "~/mixins/banner";
export default {
  name: "ProposalImage",
  mixins: [banner],
  computed: {
    src() {
      let isXs = this.$vuetify.breakpoint.xsOnly;

      let prop =
        "metaData.category_proposal_type." +
        (isXs ? "IMAGE_MOBILE" : "IMAGE_DESKTOP");
      return get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
