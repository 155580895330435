var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner-slider-container",class:("position-" + (_vm.config.templateBlock.codInt))},[_c('ebsn-meta',{class:' banner-slider-title text-center ',attrs:{"target":_vm.config,"path":"categoryblocktype_BannerSlider.TITLE","tag":"h2"}}),_c('ebsn-meta',{class:' banner-slider-description text-center ',attrs:{"target":_vm.config,"path":"categoryblocktype_BannerSlider.DESCRIPTION","tag":"div"}}),_c('div',{staticClass:"slider banner-slider"},[_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.config.proposals),function(proposal){return _c('swiper-slide',{key:proposal.id},[_c(_vm.getProposalModelName(proposal),{tag:"component",attrs:{"proposal":proposal,"position":_vm.config.templateBlock.codInt}})],1)}),1),(
        _vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_BannerSlider.SHOW_BULLETS',
          true
        ) && _vm.config.proposals.length > 1
      )?_c('div',{staticClass:"swiper-pagination",attrs:{"id":("banner-pagination-" + (_vm.config.categoryBlockId))}}):_vm._e(),(
        _vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_BannerSlider.SHOW_ARROWS',
          false
        ) && _vm.config.proposals.length > 1
      )?_c('div',{staticClass:"swiper-button-prev",attrs:{"id":("banner-slider-prev-" + (_vm.config.categoryBlockId))}}):_vm._e(),(
        _vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_BannerSlider.SHOW_ARROWS',
          false
        ) && _vm.config.proposals.length > 1
      )?_c('div',{staticClass:"swiper-button-next",attrs:{"id":("banner-slider-next-" + (_vm.config.categoryBlockId))}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }