<template>
  <v-hover v-slot="{ hover }">
    <v-card
      rounded="md"
      class="product-card product-card-special"
      :id="`product-card-${product.slug}`"
      :elevation="hover ? 5 : 0"
      :to="{ name: 'Product', params: { productSlug: product.slug } }"
      :key="componentKey"
    >
      <div
        class="product px-1 pt-0"
        :class="[promoClass]"
        @click="logClickProduct"
      >
        <div
          class="header d-flex flex-row align-center px-2"
          :class="`justify-${warehousePromoBody ? 'space-between' : 'end'}`"
        >
          <ProductPromo
            v-if="warehousePromoBody"
            :warehousePromo="product.warehousePromo"
          />
          <div class="icons-box">
            <ProductAcqLimit :product="product" />
          </div>
        </div>
        <div class="d-flex body fill-width flex-column">
          <div class="description">
            <div class="header-block">
              <template
                v-if="$ebsn.meta(product, 'productInfos.PRODUCT_SPECIAL_NAME')"
              >
                <div
                  class="product-special-name"
                  v-html="
                    $ebsn.meta(product, 'productInfos.PRODUCT_SPECIAL_NAME')
                  "
                ></div>
              </template>
              <template v-else>
                <div class="product-name">
                  {{ product.name }}
                </div>
                <span
                  class="product-short-descr"
                  v-if="product.description.length"
                >
                  {{ product.shortDescr }}
                </span>
              </template>
              <span
                class="option"
                style="white-space: initial;text-align: right;"
                v-for="(value, label) in selectedOptions"
                :key="label"
              >
                Opzione <br class="xs-only-show" />selezionata:<b>
                  {{ value }}</b
                >
              </span>
            </div>

            <ProductPrice :product="product" />
          </div>
          <img
            :src="product.mediaURL"
            class="product-img align-self-center mt-2"
            :width="$vuetify.breakpoint.xlOnly ? 150 : 100"
            :height="$vuetify.breakpoint.xlOnly ? 150 : 100"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          />

          <v-row no-gutters class="actions">
            <template v-if="product.available > 0">
              <div class="qty-wrapper d-flex flex-row">
                <ProductQty
                  :key="key"
                  :selectedOptions="selectedOptions"
                  :product="product"
                  :item="item"
                  :addToCartLabel="$t('product.addToCartSpecial')"
                />
              </div>
            </template>
            <div v-else class="not-available">
              Il prodotto non è disponibile
            </div>
          </v-row>
        </div>
      </div>
    </v-card>
  </v-hover>
</template>
<style lang="scss">
// .theme--light.v-sheet {
.product-card-special.product-card.v-card {
  border-color: $primary-promo !important;
}
// }
.product-card-special.product-card {
  .product-special-name {
    text-align: center;
  }
  .product {
    .body {
      margin-top: 4px;
    }
    .description {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .product-name {
        min-height: 20px;
        @media #{map-get($display-breakpoints, 'xl-only')} {
          font-size: 1.3rem;
          line-height: 2rem;
          -webkit-line-clamp: 3;
        }
      }
    }
  }
  .product-price {
    .price-cnt {
      justify-content: center !important;
      gap: 10px;
    }
    .product-price-block {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 10px;
    }
  }

  .xs-only-show {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      display: none;
    }
  }
  .option {
    font-size: 11px;
    height: 0;
    text-align: right;
    width: 100%;
  }
  .selectOptionsMenu {
    z-index: 12;
    // .v-menu__content {
    //   min-width: 100% !important;
    //   max-width: 100% !important;
    // }
  }
  .selectOptions {
    min-width: 10px !important;
    border: 1px solid var(--v-primary-lighten1);
    padding: 0 8px !important;
  }
  .not-available {
    color: red;
    font-size: 12px;
    white-space: normal;
    height: 40px;
    display: flex;
    align-items: flex-end;
    text-align: right;
  }
  :deep(.v-tooltip__content) {
    background: var(--v-primary-lighten2);
    color: $white;
    text-align: center;
    line-height: 1.5;
    max-width: 180px;
    font-weight: 600;
    padding: 2px 5px;
  }
  .display-option {
    font-size: 10px;
    position: absolute;
    top: 180px;
    right: 10px;
    background: var(--v-primary-base);
    color: $white;
    padding: 2px;
    border-radius: $border-radius-root;
  }
}
</style>
<script>
//TODO sistemare la visualizzazione della card su iphone SE dimensione della description
// import ProductClass from "./ProductClass";
import ProductPromo from "./ProductPromo.vue";
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductPrice from "./ProductPrice.vue";
// import SelectOptionsMenu from "./SelectOptionsMenu.vue";
import { formatProductCode } from "@/service/eurospinUtils";

import { mapState, mapGetters } from "vuex";
import productMixin from "~/mixins/product";
import ProductQty from "./ProductQty.vue";
import analyticsService from "~/service/analyticsService";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import forEach from "lodash/forEach";

export default {
  name: "ProductCardSpecial",
  props: { product: { type: Object, required: true } },
  // eslint-disable-next-line vue/no-unused-components
  components: {
    // ProductClass,
    ProductPromo,
    ProductAcqLimit,
    ProductPrice,
    ProductQty
    // ,
    // SelectOptionsMenu
  },
  data() {
    return {
      componentKey: 0,
      selectOptionsArray: [],
      selectedOptions: {},
      displayOption: null,
      key: 0
    };
  },
  mixins: [productMixin],
  created() {
    if (this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      getItem: "cart/getItem"
    }),
    productCode() {
      return formatProductCode(this.product);
    },
    warehousePromoBody() {
      return get(this.product, "warehousePromo.view.body", null);
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      this.key;
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    },
    selectedOptionLabels() {
      let opts = [];
      if (this.selectedOptions) {
        forEach(this.selectedOptions, (value, key) => {
          if (key !== "notes") {
            opts.push(value);
          }
        });
      }
      return opts;
    }
  },
  methods: {
    logClickProduct() {
      analyticsService.clickProduct(this.product, this.position);
    },
    onOptionChanged(selectOption, option) {
      // this.optionChanged(selectOption);
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.key;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    }
  }
};
</script>
