global.config = {
  google_api_key: process.env.VUE_APP_GOOGLE_API_KEY,
  googleGeocodeParams: {
    components: "country:it|administrative_area:Lombardia"
  },
  baseUrl: "",
  proximityDefaultDistance: "10000.0",
  showSectors: false,
  showTimeslotAutoassigned: false,
  showInvoiceForWarehouseId: [89],
  dksId: 89,
  hashNotReload: ["/cart/", "/payment"],
  filterEmptyCategory: true,
  dayWordsSaved: 30,
  version: process.env.VUE_APP_VERSION,
  minProfileLevel: 2,
  registration: true,
  checkVersionInterval: 1000 * 60 * 10, //ogni 10 minuti
  reloadAfterMillis: 1000 * 60 * 30, //dopo 30 minuti di inattività restarta la app
  updateCart: true,
  domainWhitelist: [
    "www.eurospin.it",
    "eurospin.it",
    "test-eurospin.digitelematica.org",
    "localhost",
    "eurospinb2btest.digitelematica.org",
    "b2b.eurospin.it"
  ],
  cartItemInfos: [
    "is_package",
    "notes",
    "size",
    "custom_weight",
    "delivery_warehouse_id",
    "use_wpid"
  ],
  analyticsClass: "analyticsServiceGTM",
  customCountries: [
    {
      countryId: 1,
      iso: "IT",
      name: "Italia"
    },
    {
      countryId: 2,
      iso: "MT",
      name: "Malta"
    },
    {
      countryId: 3,
      iso: "DE",
      name: "Germania"
    },
    /*{
      countryId: 4,
      iso: "RO",
      name: "Romania"
    },*/
    {
      countryId: 5,
      iso: "FR",
      name: "Francia"
    }
  ],
  customRegions: [
    {
      itemId: 1,
      name: "Calabria"
    },
    {
      itemId: 2,
      name: "Sardegna"
    },
    {
      itemId: 3,
      name: "Sicilia"
    }
  ],
  companyTypes: [
    {
      value: 2,
      text: "Azienda",
      countries: [1, 2, 3, 5]
    },
    {
      value: 4,
      text: "Pubblica Amministrazione",
      countries: [1]
    }
  ],
  sectorTypes: [
    { value: 4471, text: "RISTORAZIONE" },
    { value: 4472, text: "PUBBLICA AMMINISTRAZIONE" },
    { value: 4473, text: "STRUTTURE SOCIO - SANITARIE - ASSISTENZIALI" },
    { value: 4474, text: "STRUTTURE RICETTIVE" },
    { value: 4475, text: "LIBERO PROFESSIONISTA" },
    { value: 4476, text: "ALTRO" }
  ],
  productDescriptionWarningsKeyList: [
    "productIdentifier",
    "warnings",
    "otherWarnings",
    "safetyPhrases",
    "clpImages",
    "aiseImages"
  ],
  recaptchaTokenEnabled: true,
  recaptchaEnabledEndpoints: [
    "/ebsn/api/registration/user_detail",
    "/ebsn/api/registration/update_user_detail",
    "/ebsn/api/auth/login",
    "/ebsn/api/registration-card/update_password"
  ]
};
