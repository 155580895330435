var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"fill-height":"","column":""}},[(_vm.category.metaData && _vm.category.metaData.category_info)?_c('div',{staticClass:"d-flex flex-column justify-center mb-6 timeslot-selector"},[_c('div',{staticClass:"text-h4 font-weight-bold primary--text text--lighten-1 text-center ma-4 mb-0"},[_vm._v(" "+_vm._s(_vm.category.metaData.category_info.TITLE)+" ")]),(_vm.category.metaData.category_info.SUBTITLE)?_c('h5',{staticClass:"subtitle-1 primary--text"},[_vm._v(" "+_vm._s(_vm.category.metaData.category_info.SUBTITLE)+" ")]):_vm._e(),(_vm.category.metaData.category_info.DESCRIPTION)?_c('div',{staticClass:"text-h6 primary--text text-center",domProps:{"innerHTML":_vm._s(_vm.category.metaData.category_info.DESCRIPTION)}}):_vm._e(),(_vm.deliveryServices.length > 1)?_c('v-row',{staticClass:"align-center justify-space-around mt-6",attrs:{"no-gutters":""}},_vm._l((_vm.deliveryServices),function(service){return _c('v-col',{key:service.deliveryServiceId,staticClass:"pa-5",attrs:{"cols":"12","sm":_vm.deliveryServiceButtonsLength},on:{"click":function($event){return _vm.fetchAddresses(service)}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('div',{staticClass:"text-center px-5 py-5 py-sm-0 tab d-flex align-center justify-center",class:{
            selected:
              _vm.deliveryService.deliveryServiceId === service.deliveryServiceId
          }},[_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(_vm._s(service.name))])])])}),1):_vm._e(),(_vm.deliveryServices.length > 1)?_c('div',{staticClass:"px-4 text-center font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.deliveryService.descr)}}):_vm._e()],1):_vm._e(),_c('v-card',{staticClass:"pb-2",attrs:{"loading":_vm.loading,"flat":""}},[(!_vm.addresses.length)?_c('v-row',{staticClass:"ma-4 justify-center",attrs:{"no-gutters":""}},[(_vm.deliveryService.deliveryServiceId === 2)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{attrs:{"color":"primary","height":"40","depressed":""},on:{"click":function($event){return _vm.openAddAddress()}}},[_vm._v(" "+_vm._s(_vm.$t("addresses.addAddressButton"))+" ")])],1):_vm._e()],1):_vm._e(),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"mandatory":_vm.selectedItemGroup != -1,"color":"primary"},model:{value:(_vm.shippingAddress),callback:function ($$v) {_vm.shippingAddress=$$v},expression:"shippingAddress"}},_vm._l((_vm.filteredAddresses),function(address,index){return _c('v-list-item',{key:address.addressId,attrs:{"two-line":""},on:{"click":function($event){return _vm.setAddress(address, index)}}},[_c('v-list-item-avatar',[(
                address.addressId == _vm.cart.shippingAddress.addressId &&
                  !_vm.cart.shippingAddress.autoAssigned
              )?_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline")]):_c('v-icon',[_vm._v("mdi-checkbox-blank-circle-outline")])],1),_c('v-list-item-content',{staticClass:"align-self-center"},[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(address.addressName)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + address.addressTypeId + ".list", address ))+" ")])],1),_c('v-list-item-action',{staticClass:"flex-row align-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(
                    address.addressId == _vm.cart.user.defaultStoreAddressId ||
                      address.addressId == _vm.cart.user.defaultDeliveryAddressId
                  )?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-star-outline")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(address.addressType == "home" ? "Indirizzo preferito" : "Negozio preferito"))])])],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }