var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{key:_vm.componentKey,staticClass:"product-card",attrs:{"flat":"","rounded":"md","to":{ name: 'Product', params: { productSlug: _vm.product.slug } }}},[_c('div',{staticClass:"product pa-2 pt-0",class:[_vm.promoClass],on:{"click":_vm.logClickProduct}},[_c('div',{staticClass:"header d-flex justify-end align-center"},[_c('div',{staticClass:"product-code"},[_vm._v(" Cod. Art. "),_c('strong',[_vm._v(_vm._s(_vm.productCode))])]),_c('div',{staticClass:"icons-box"},[_c('ProductAcqLimit',{attrs:{"product":_vm.product}}),_c('v-tooltip',{key:_vm.heartKey,attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"heart",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addToFavorites.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[(_vm.highlight)?_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-heart")]):_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-heart-outline")])],1)]}}])},[_c('span',[_vm._v("Aggiungi ad una lista")])])],1)]),_c('div',{staticClass:"d-flex body fill-width flex-column"},[_c('img',{staticClass:"product-img align-self-center",attrs:{"src":_vm.product.mediaURL,"width":_vm.$vuetify.breakpoint.xs ? 100 : 150,"height":_vm.$vuetify.breakpoint.xs ? 100 : 150,"alt":'Immagine' + _vm.product.name,"title":_vm.product.name + ' (' + _vm.product.codInt + '-' + _vm.product.codVar + ')'}}),_c('div',{staticClass:"description mt-2"},[_c('div',{staticClass:"product-name"},[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),_c('div',{staticClass:"product-descr"},[(_vm.product.description)?_c('span',[(_vm.product.productInfos.DRAINED_WEIGHT_DESCR)?[_vm._v(" "+_vm._s(_vm.product.productInfos.DRAINED_WEIGHT_DESCR)+" ")]:[_vm._v(" "+_vm._s(_vm.product.description)+" ")]],2):_vm._e()]),_c('div',{staticClass:"product-brand"},[_vm._v(" "+_vm._s(_vm.product.shortDescr)+" ")]),(
            _vm.product.metaData &&
              _vm.product.metaData.product_info &&
              _vm.product.metaData.product_info.weight_description
          )?_c('div',{staticClass:"product-descr"},[_vm._v(" "+_vm._s(_vm.product.metaData.product_info.weight_description)+" ")]):_vm._e(),_c('div',{staticClass:"product-descr-weight"},[(
              _vm.product.productInfos.LOGISTIC_PACKAGING_ITEMS ||
                _vm.product.logisticPackagingItems
            )?_c('strong',[_vm._v(" Collo: "+_vm._s(_vm.product.productInfos.LOGISTIC_PACKAGING_ITEMS || _vm.product.logisticPackagingItems)+" pz - ")]):_vm._e(),_c('span',{staticClass:"weight-unit"},[_vm._v(" "+_vm._s(_vm.product.ivaCategory.descr)+" ")])]),_c('ProductPrice',{attrs:{"product":_vm.product}})],1),_c('div',{staticClass:"actions"},[_vm._l((_vm.selectOptionsArray),function(selectOption){return _c('div',{key:selectOption.name,staticClass:"d-flex align-center justify-center pa-2",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"mousedown":function($event){$event.stopPropagation();}}},[(selectOption.parameterName == 'size')?_c('v-tooltip',{attrs:{"bottom":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("$info")])]}}],null,true)},[_c('span',[_vm._v("il gusto non è garatito per i prodotti a collo")])]):_vm._e(),_c('v-select',{attrs:{"outlined":"","items":selectOption.options,"label":selectOption.name,"item-text":"key","item-value":"value","menu-props":{ maxWidth: '55%' },"hide-details":true},on:{"change":function($event){return _vm.optionChanged(selectOption)}},model:{value:(_vm.selectedOptions[selectOption.parameterName]),callback:function ($$v) {_vm.$set(_vm.selectedOptions, selectOption.parameterName, $$v)},expression:"selectedOptions[selectOption.parameterName]"}})],1)}),(_vm.product.available > 0)?[_c('div',{staticClass:"qty-wrapper"},[(_vm.product.productInfos.QTY_LOCKED != 'true')?_c('ProductQty',{attrs:{"isPackage":'false',"selectedOptions":_vm.selectedOptions,"product":_vm.product,"item":_vm.item2}}):_vm._e()],1),_c('div',{staticClass:"qty-wrapper"},[(_vm.product.productInfos.QTY_MULT > 1)?_c('ProductQty',{attrs:{"isPackage":'true',"selectedOptions":_vm.selectedOptions,"product":_vm.product,"item":_vm.item}}):_vm._e()],1)]:_c('div',{staticClass:"not-available"},[_vm._v(" Il prodotto non è disponibile ")])],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }