<template>
  <v-card
    class="product-slider-card"
    rounded="sm"
    elevation="0"
    flat
    :to="link"
    :style="`backgroundColor : ${backgroundColor}`"
    @click.prevent="handleLink"
  >
    <v-img
      :src="img"
      contain
      alt="Immagine categoria"
      class="product-slider-card-image"
    />
    <v-card-text v-if="text" v-html="text"> </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions v-if="link && linkLabel" class="pa-1">
      <v-btn :to="link" color="primary lighten-1" large block
        >{{ linkLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.product-slider-card {
  display: flex;
  flex-direction: column;
}
</style>
<script>
export default {
  name: "ProductSliderCard",
  props: {
    img: { type: String, required: true },
    text: { type: String, required: true },
    link: { type: [String, Object], required: false },
    linkLabel: { type: String, required: false },
    backgroundColor: { type: String, required: false, default: "white" }
  }
};
</script>
