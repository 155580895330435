<template>
  <v-card
    :href="link"
    @click.prevent="handleLink"
    :style="cardStyle"
    class="proposal-card-overlay"
    v-intersect.once="handleView"
  >
    <v-img
      eager
      :src="imgSrc"
      :alt="proposal.imgAlt"
      :title="proposal.imgDescription"
    >
      <div class="blurred-bg"></div>
      <div
        class="text-overlay"
        :class="
          $ebsn.meta(
            proposal,
            'metaData.category_proposal_type.TEXT_ALIGN',
            ' '
          )
        "
      >
        <v-card-title
          v-if="proposal.descr"
          v-html="proposal.descr"
        ></v-card-title>
        <v-card-text
          v-if="proposal.content"
          v-html="proposal.content"
        ></v-card-text>
        <v-card-actions
          v-if="
            $ebsn.meta(proposal, 'metaData.category_proposal_type.BTN_TEXT')
          "
        >
          <v-btn
            :href="link"
            :outlined="
              $ebsn.meta(
                proposal,
                'metaData.category_proposal_type.BTN_OUTLINED',
                false
              )
            "
            :color="
              $ebsn.meta(proposal, 'metaData.category_proposal_type.BTN_COLOR')
            "
            >{{
              $ebsn.meta(proposal, "metaData.category_proposal_type.BTN_TEXT")
            }}</v-btn
          >
        </v-card-actions>
      </div>
    </v-img>
  </v-card>
</template>
<style lang="scss">
.proposal-card-overlay.v-card {
  overflow: hidden;
  height: 100%;
  .blurred-bg:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 30%, black 100%);
  }
  .v-card__title,
  .v-card__text {
    color: var(--v-white-base);
  }
  .v-image {
    height: 100%;
    color: inherit;
  }
  .text-overlay {
    bottom: 0px;
    position: absolute;
    width: 100%;
  }
}
</style>
<script>
import banner from "~/mixins/banner";

import get from "lodash/get";

export default {
  name: "ProposalCardOverlay",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    cardStyle() {
      let style = {};
      let backgroundColor = get(
        this.proposal,
        `metaData.category_proposal_type.BACKGROUND_COLOR`
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
      }
      let textColor = get(
        this.proposal,
        `metaData.category_proposal_type.TEXT_COLOR`
      );
      if (textColor) {
        style.color = textColor;
      }

      return style;
    },
    imgSrc() {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
