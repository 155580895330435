import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "./i18n";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    //disable: true,
    light: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          base: "#003961",
          lighten1: "#005db9",
          lighten2: "#569cff"
        },
        secondary: {
          base: "#ffc200",
          darken1: "#ffc200",
          lighten1: "#fceb99",
          lighten2: "#FFF5D6"
        },
        grey: {
          base: "#c4c4c4",
          darken1: "#636363",
          darken2: "#333",
          lighten1: "#868686",
          lighten2: "#D5D5D5",
          lighten3: "#F8F8F8",
          lighten4: "#D4D4D4"
        },
        background: "#f2f2f2",
        accent: "#DEECE9"
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    values: {
      arrowTopLeft: "icon-arrow-top-left",
      barcodeScan: "icon-barcode-scan",
      bin: "icon-bin",
      calendar: "icon-calendar",
      cart: "icon-cart",
      check: "icon-check",
      chevronDown: "icon-chevron-down",
      chevronDownLight: "icon-chevron-down-light",
      chevronLeft: "icon-chevron-left",
      chevronRight: "icon-chevron-right",
      chevronUp: "icon-chevron-up",
      clock: "icon-clock",
      consegna: "icon-consegna",
      creditcard: "icon-creditcard",
      delete: "icon-delete",
      edit: "icon-edit",
      eye: "icon-eye",
      eyeOff: "icon-eye-off",
      fastSearch: "icon-fast-search",
      filter: "icon-filter",
      heart: "icon-heart",
      heartfull: "icon-heartfull",
      info: "icon-info",
      instagram: "icon-instagram",
      invoice: "icon-invoice",
      list: "icon-list",
      menu: "icon-burgermenu",
      menuDown: "icon-menu-down",
      menuMobile: "icon-menu-mobile",
      minus: "icon-minus",
      phone: "icon-phone",
      placeholder: "icon-placeholder",
      plus: "icon-plus",
      print: "icon-print",
      profile: "icon-profile",
      profilefull: "icon-profilefull",
      promo: "icon-promo",
      qrcode: "icon-qr-code",
      radioChecked: "icon-radio-checked",
      radioUnchecked: "icon-radio-unchecked",
      reorder: "icon-reorder",
      rightmenu: "icon-dots",
      search: "icon-search",
      searchPlus: "icon-search-plus",
      star: "icon-star",
      starfull: "icon-starfull",
      swapVertical: "icon-swap-vertical",
      tuneVariant: "icon-tune-variant",
      package: "icon-package",
      form: "icon-form",
      mail: "icon-mail",
      phone1: "icon-phone-1",
      whatsapp: "icon-whatsapp",
      youtube: "icon-youtube",
      facebook: "icon-facebook",
      chat: "icon-chat",
      share: "icon-share",
      copy: "icon-copy",
      gift: "icon-gift"
    }
  }
});
