<template>
  <!-- this type of banner is a vertical card -->
  <v-card
    rounded="md"
    :href="link"
    outlined
    @click.prevent="handleLink"
    :style="getStyle('BACKGROUND_COLOR', 'BORDER_COLOR')"
    class="proposal-card d-flex"
    v-intersect.once="handleView"
    :class="
      $ebsn.meta(
        proposal,
        'metaData.category_proposal_type.RESPONSIVE_BREAKPOINTS',
        ' card-no-responsive '
      ) +
        ' ' +
        $ebsn.meta(
          proposal,
          'metaData.category_proposal_type.TEXT_ALIGN_HORIZONTAL',
          ' '
        ) +
        ' ' +
        $ebsn.meta(
          proposal,
          'metaData.category_proposal_type.TEXT_ALIGN_VERTICAL',
          ' '
        ) +
        ' ' +
        `${
          $ebsn.meta(
            proposal,
            'metaData.category_proposal_type.OPPOSITE_LAYOUT',
            false
          )
            ? 'opposite-layout '
            : ' '
        }`
    "
  >
    <div
      class="image-card-container"
      :style="
        `--image-containerwidth: ${$ebsn.meta(
          proposal,
          'metaData.category_proposal_type.IMAGE_WIDTH_PERCENT',
          '100'
        )}%; ${getStyle('BG_IMAGE_COLOR')}`
      "
    >
      <v-img
        eager
        class="image-card"
        :src="imgSrc"
        :alt="proposal.imgAlt"
        :title="proposal.imgDescription"
        :contain="
          $ebsn.meta(
            proposal,
            'metaData.category_proposal_type.IMAGE_CONTAIN',
            false
          )
        "
      />
    </div>
    <div
      class="card-content"
      :class="
        $ebsn.meta(proposal, 'category_proposal_type.TEXT_ALIGN_HORIZONTAL')
      "
    >
      <v-card-title v-if="proposal.descr">{{ proposal.descr }}</v-card-title>
      <v-card-text v-if="proposal.content" v-html="proposal.content">
      </v-card-text>
      <v-spacer
        v-if="link && $ebsn.meta(proposal, 'category_proposal_type.BTN_TEXT')"
      ></v-spacer>
      <v-card-actions
        v-if="link && $ebsn.meta(proposal, 'category_proposal_type.BTN_TEXT')"
      >
        <v-btn
          :href="link"
          @click.prevent="handleLink"
          depressed
          large
          min-width="200"
          :color="
            $ebsn.meta(
              proposal,
              'metaData.category_proposal_type.BTN_COLOR',
              'primary'
            )
          "
          :outlined="
            $ebsn.meta(proposal, 'category_proposal_type.BTN_OUTLINED', false)
          "
        >
          {{ $ebsn.meta(proposal, "category_proposal_type.BTN_TEXT") }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>
<style lang="scss">
.proposal-card {
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  .image-card-container {
    .image-card {
      height: initial;
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        height: 100%;
      }
    }
  }
  .card-content {
    height: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .v-card__title {
    font-size: 20px;
    font-weight: bold;
    word-break: break-word;
  }
  .text-align-center {
    .v-card__title,
    .v-card__actions {
      text-align: center;
      justify-content: center;
    }
    .v-card__text {
      text-align: center;
    }
  }
  .text-align-center {
    .v-card__title,
    .v-card__actions {
      text-align: center;
      justify-content: center;
    }
    .v-card__text {
      text-align: center;
    }
  }
  .text-align-right {
    .v-card__title,
    .v-card__actions {
      text-align: right;
      justify-content: flex-end;
    }
    .v-card__text {
      text-align: right;
    }
  }
  .btn-block {
    .v-btn {
      display: flex;
      flex: 1 0 auto;
      min-width: 100% !important;
      max-width: auto;
    }
  }
  &.opposite-layout.flex-row {
    // horizontal card from xs and up
    .image-card-container {
      order: 2;
      margin-left: auto;
    }
    .card-content {
      order: 1;
    }
    &.text-align-center {
      .card-content {
        justify-content: center;
      }
    }
    &.text-align-top {
      .card-content {
        justify-content: flex-start;
      }
    }
    &.text-align-bottom {
      .card-content {
        justify-content: flex-end;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    &.flex-sm-row {
      // horizontal card from sm and up
      .image-card-container {
        width: var(--image-containerwidth);
      }
    }
    &.opposite-layout.flex-sm-row {
      .image-card-container {
        order: 2;
        margin-left: auto;
      }
      .card-content {
        order: 1;
      }
      &.text-align-center {
        .card-content {
          justify-content: center;
        }
      }
      &.text-align-top {
        .card-content {
          justify-content: flex-start;
        }
      }
      &.text-align-bottom {
        .card-content {
          justify-content: flex-end;
        }
      }
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    // horizontal card from md and up
    &.flex-md-row {
      .image-card-container {
        width: var(--image-containerwidth);
      }
    }
    &.opposite-layout.flex-md-row {
      .image-card-container {
        order: 2;
        margin-left: auto;
      }
      .card-content {
        order: 1;
      }
      &.text-align-center {
        .card-content {
          justify-content: center;
        }
      }
      &.text-align-top {
        .card-content {
          justify-content: flex-start;
        }
      }
      &.text-align-bottom {
        .card-content {
          justify-content: flex-end;
        }
      }
    }
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    // horizontal card from lg and up
    &.flex-lg-row {
      .image-card-container {
        width: var(--image-containerwidth);
      }
    }
    &.opposite-layout.flex-lg-row {
      .image-card-container {
        order: 2;
        margin-left: auto;
      }
      .card-content {
        order: 1;
      }
      &.text-align-center {
        .card-content {
          justify-content: center;
        }
      }
      &.text-align-top {
        .card-content {
          justify-content: flex-start;
        }
      }
      &.text-align-bottom {
        .card-content {
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>
<script>
import banner from "~/mixins/banner";

import get from "lodash/get";

export default {
  name: "ProposalCard",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    imgSrc() {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(this.proposal, prop, this.proposal.img);
    }
  },
  methods: {
    getStyle(backgroundPath, borderColorPath) {
      let style = {};

      if (backgroundPath) {
        let backgroundColor = get(
          this.proposal,
          `metaData.category_proposal_type.${backgroundPath}`
        );
        if (backgroundColor) {
          style.backgroundColor = backgroundColor + " !important";
        }
      }
      if (borderColorPath) {
        let borderColor = get(
          this.proposal,
          `metaData.category_proposal_type.${borderColorPath}`
        );
        if (borderColor) {
          style.borderColor = borderColor;
        }
      }

      return style;
    }
  }
};
</script>
