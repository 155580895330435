<template>
  <v-layout fill-height column>
    <div
      class="d-flex flex-column justify-center mb-6 timeslot-selector"
      v-if="category.metaData && category.metaData.category_info"
    >
      <div
        class="text-h4 font-weight-bold primary--text text--lighten-1 text-center ma-4 mb-0"
      >
        {{ category.metaData.category_info.TITLE }}
      </div>
      <h5
        class="subtitle-1 primary--text"
        v-if="category.metaData.category_info.SUBTITLE"
      >
        {{ category.metaData.category_info.SUBTITLE }}
      </h5>

      <div
        class="text-h6 primary--text text-center"
        v-if="category.metaData.category_info.DESCRIPTION"
        v-html="category.metaData.category_info.DESCRIPTION"
      ></div>
      <v-row
        no-gutters
        class="align-center justify-space-around mt-6"
        v-if="deliveryServices.length > 1"
      >
        <v-col
          v-for="service in deliveryServices"
          :key="service.deliveryServiceId"
          @click="fetchAddresses(service)"
          v-model="tab"
          class="pa-5"
          cols="12"
          :sm="deliveryServiceButtonsLength"
        >
          <div
            class="text-center px-5 py-5 py-sm-0 tab d-flex align-center justify-center"
            :class="{
              selected:
                deliveryService.deliveryServiceId === service.deliveryServiceId
            }"
          >
            <span class="white--text font-weight-bold">{{ service.name }}</span>
          </div>
        </v-col>
      </v-row>
      <div
        class="px-4 text-center font-weight-bold"
        v-html="deliveryService.descr"
        v-if="deliveryServices.length > 1"
      ></div>
    </div>
    <v-card :loading="loading" flat class="pb-2">
      <v-row no-gutters class="ma-4 justify-center" v-if="!addresses.length">
        <!-- <v-col
          :cols="12"
          :sm="deliveryService.deliveryServiceId === 2 ? 8 : 12"
          v-if="addresses.length"
        >
          <v-text-field
            hide-details
            v-model="searchText"
            clearable
            outlined
            dense
            flat
            :label="
              $t('navbar.service.' + selectedDeliveryServiceId + '.searchLabel')
            "
          >
            <v-icon slot="append">
              mdi-magnify
            </v-icon>
          </v-text-field>
        </v-col> -->
        <v-col
          cols="12"
          sm="4"
          v-if="deliveryService.deliveryServiceId === 2"
          class="d-flex justify-center"
        >
          <v-btn
            color="primary"
            height="40"
            depressed
            @click="openAddAddress()"
          >
            {{ $t("addresses.addAddressButton") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-list dense>
        <v-list-item-group
          v-model="shippingAddress"
          :mandatory="selectedItemGroup != -1"
          color="primary"
        >
          <v-list-item
            two-line
            v-for="(address, index) in filteredAddresses"
            :key="address.addressId"
            @click="setAddress(address, index)"
          >
            <v-list-item-avatar>
              <!-- v-if="cart.shippingAddress.addressId == address.addressId" -->
              <v-icon
                v-if="
                  address.addressId == cart.shippingAddress.addressId &&
                    !cart.shippingAddress.autoAssigned
                "
                >mdi-checkbox-marked-circle-outline</v-icon
              >
              <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="align-self-center">
              <v-list-item-title class="font-weight-bold">
                {{ address.addressName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{
                  $t(
                    "navbar.address." + address.addressTypeId + ".list",
                    address
                  )
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="flex-row align-center">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    v-if="
                      address.addressId == cart.user.defaultStoreAddressId ||
                        address.addressId == cart.user.defaultDeliveryAddressId
                    "
                    >mdi-star-outline</v-icon
                  >
                </template>
                <span>{{
                  address.addressType == "home"
                    ? "Indirizzo preferito"
                    : "Negozio preferito"
                }}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-layout>
</template>

<script>
// @ is an alias to /src
import StoreService from "~/service/storeService";
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import { mapState } from "vuex";
var vm;
export default {
  name: "AddressSelector",
  data() {
    return {
      store: {},
      addresses: [],
      loading: null,
      shippingAddress: null,
      deliveryService: null,
      tab: 2,
      searchText: null,
      category: {},
      selectedItemGroup: -1
    };
  },
  computed: {
    filteredAddresses() {
      if (this.searchText) {
        return this.addresses.filter(address => {
          return (
            address.addressName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.address1
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.city.toLowerCase().indexOf(this.searchText.toLowerCase()) >
              -1
          );
        });
      } else {
        return this.addresses;
      }
    },
    deliveryServiceButtonsLength() {
      console.log(12 / this.deliveryServices.length);
      return 12 / this.deliveryServices.length;
    },
    ...mapState({
      cart: ({ cart }) => cart.cart,
      selectedService: ({ cart }) => cart.selectedService
    }),
    selectedDeliveryServiceId() {
      return this.deliveryService ? this.deliveryService.deliveryServiceId : 0;
    },
    deliveryServices() {
      if (this.store && this.store.deliveryServices) {
        return this.store.deliveryServices.filter(button => {
          return button.showTab;
        });
      }
      return [];
    }
  },
  methods: {
    async remove(address) {
      const res = await vm.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        let response = await DeliveryService.removeAddress(address.addressId);
        vm.addresses = response.addresses;
      }
    },
    edit(address) {
      // _this.address = address;
      vm.$router.push({
        path: "/profile/addresses/manage/" + address.addressId
      });
      vm.$emit("submit", true);
    },
    async fetchStore() {
      let store = await StoreService.getStoreById(vm.cart.store.storeId);
      vm.store = store;

      vm.store.deliveryServices.find(deliveryService => {
        if (
          deliveryService.deliveryServiceId ==
          vm.cart.shippingAddress.deliveryServiceId
        ) {
          vm.deliveryService = deliveryService;
        }
      });
    },
    async fetchAddresses(deliveryService) {
      vm.loading = true;
      vm.searchText = null;
      let deliveryServiceId =
        deliveryService.deliveryServiceId || deliveryService;
      vm.deliveryService = deliveryService;
      vm.addresses = await StoreService.getAddressesByDeliveryService(
        deliveryServiceId
      );

      vm.addresses.find((address, index) => {
        if (address.addressId == vm.cart.shippingAddress.addressId) {
          vm.shippingAddress = index;
        }
      });
      vm.loading = false;
    },
    async setAddress(address, index) {
      if (vm.shippingAddress === index) {
        return;
      }
      this.loading = true;
      let data = await this.$store.dispatch("cart/setAddress", {
        addressType: address.addressType,
        addressId: address.addressId
      });

      this.loading = false;
      if (data) {
        vm.$emit("submit", true);
      }
    },
    getServiceImg(serviceId) {
      return this.$t("navbar.service." + serviceId + ".icon");
    },
    openAddAddress() {
      this.$router.push({
        path: "/profile/addresses/manage"
      });
      this.$emit("submit", false);
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "service-selector-b2b"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  created() {
    vm = this;
    this.fetchStore();
    this.fetchAddresses(this.cart.shippingAddress.deliveryServiceId);
    this.fetchCategory();
  }
};
</script>

<style scoped lang="scss">
.tab {
  background: var(--v-primary-base);
  min-height: 100px;

  &.selected {
    background: var(--v-secondary-base) !important;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-height: auto;
  }
}
</style>
