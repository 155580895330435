<template>
  <v-card
    flat
    rounded="md"
    class="product-card"
    :to="{ name: 'Product', params: { productSlug: product.slug } }"
    :key="componentKey"
  >
    <div
      class="product pa-2 pt-0"
      :class="[promoClass]"
      @click="logClickProduct"
    >
      <div class="header d-flex justify-end align-center">
        <div class="product-code">
          Cod. Art. <strong>{{ productCode }}</strong>
        </div>
        <div class="icons-box">
          <ProductAcqLimit :product="product" />
          <v-tooltip left :key="heartKey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click.stop.prevent="addToFavorites"
                @mousedown.stop
                class="heart"
              >
                <v-icon color="secondary" v-if="highlight">mdi-heart</v-icon>
                <v-icon v-else color="secondary">mdi-heart-outline</v-icon>
              </v-btn>
            </template>
            <span>Aggiungi ad una lista</span>
          </v-tooltip>
        </div>
      </div>
      <div class="d-flex body fill-width flex-column">
        <img
          :src="product.mediaURL"
          class="product-img align-self-center"
          :width="$vuetify.breakpoint.xs ? 100 : 150"
          :height="$vuetify.breakpoint.xs ? 100 : 150"
          :alt="'Immagine' + product.name"
          :title="
            product.name + ' (' + product.codInt + '-' + product.codVar + ')'
          "
        />

        <div class="description mt-2">
          <div class="product-name">
            {{ product.name }}
          </div>

          <div class="product-descr">
            <span v-if="product.description">
              <template v-if="product.productInfos.DRAINED_WEIGHT_DESCR">
                {{ product.productInfos.DRAINED_WEIGHT_DESCR }}
              </template>
              <template v-else>
                {{ product.description }}
              </template>
            </span>
          </div>
          <div class="product-brand">
            {{ product.shortDescr }}
          </div>
          <div
            class="product-descr"
            v-if="
              product.metaData &&
                product.metaData.product_info &&
                product.metaData.product_info.weight_description
            "
          >
            {{ product.metaData.product_info.weight_description }}
          </div>
          <div class="product-descr-weight">
            <strong
              v-if="
                product.productInfos.LOGISTIC_PACKAGING_ITEMS ||
                  product.logisticPackagingItems
              "
            >
              Collo:
              {{
                product.productInfos.LOGISTIC_PACKAGING_ITEMS ||
                  product.logisticPackagingItems
              }}
              pz -
            </strong>
            <span class="weight-unit">
              {{ product.ivaCategory.descr }}
            </span>
          </div>

          <ProductPrice :product="product" />
        </div>

        <div class="actions">
          <div
            class="d-flex align-center justify-center pa-2"
            v-for="selectOption in selectOptionsArray"
            :key="selectOption.name"
            @click.stop.prevent
            @mousedown.stop
          >
            <v-tooltip bottom v-if="selectOption.parameterName == 'size'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">$info</v-icon>
              </template>
              <span>il gusto non è garatito per i prodotti a collo</span>
            </v-tooltip>

            <v-select
              outlined
              v-model="selectedOptions[selectOption.parameterName]"
              :items="selectOption.options"
              :label="selectOption.name"
              item-text="key"
              item-value="value"
              @change="optionChanged(selectOption)"
              :menu-props="{ maxWidth: '55%' }"
              :hide-details="true"
            />
          </div>
          <template v-if="product.available > 0">
            <div class="qty-wrapper">
              <ProductQty
                v-if="product.productInfos.QTY_LOCKED != 'true'"
                :isPackage="'false'"
                :selectedOptions="selectedOptions"
                :product="product"
                :item="item2"
              />
            </div>
            <div class="qty-wrapper">
              <ProductQty
                v-if="product.productInfos.QTY_MULT > 1"
                :isPackage="'true'"
                :selectedOptions="selectedOptions"
                :product="product"
                :item="item"
              />
            </div>
          </template>
          <div v-else class="not-available">
            Il prodotto non è disponibile
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>
<style scoped lang="scss">
.v-input--dense {
  height: 60px !important;
  min-height: auto !important;
  .v-input__slot {
    min-height: auto !important;
  }
  .v-select__slot {
    min-height: auto !important;
    height: 35px !important;
  }
  .v-input__append-inner {
    margin-top: 5px !important;
  }
  .v-label {
    line-height: 13px;
    font-size: 14px !important;
  }
}
.not-available {
  color: red;
  font-size: 12px;
  white-space: normal;
  height: 40px;
  display: flex;
  align-items: flex-end;
  text-align: right;
}
</style>
<script>
//TODO sistemare la visualizzazione della card su iphone SE dimensione della description
// import ProductClass from "./ProductClass";
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductPrice from "./ProductPrice.vue";
import { formatProductCode } from "@/service/eurospinUtils";

import { mapState, mapGetters } from "vuex";
import productMixin from "~/mixins/product";
import ProductQty from "./ProductQty.vue";
import analyticsService from "~/service/analyticsService";
import forEach from "lodash/forEach";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ProductCard",
  props: { product: { type: Object, required: true } },
  // eslint-disable-next-line vue/no-unused-components
  components: {
    // ProductClass,
    ProductAcqLimit,
    ProductPrice,
    ProductQty
  },
  data() {
    return {
      componentKey: 0,
      selectOptionsArray: [],
      selectedOptions: { size: "-" }
    };
  },
  mixins: [productMixin],
  created() {
    if (this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
      forEach(this.selectOptionsArray, a => {
        if (a.parameterName == "size") {
          a.options.unshift({ key: "Nessun gusto selezionato", value: "-" });
          this.selectedOptions["size"] = "-";
        }
      });
    } else {
      this.selectedOptions = {};
    }
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    productCode() {
      return formatProductCode(this.product);
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      let item = this.getItem({
        product: this.product,
        is_package: "true",
        ...this.selectedOptions
      });
      return item;
    },
    item2() {
      let item2 = this.getItem({
        product: this.product,
        is_package: "false",
        ...this.selectedOptions
      });

      return item2;
    }
  },
  methods: {
    logClickProduct() {
      analyticsService.clickProduct(this.product, this.position);
    }
  }
};
</script>
