<template>
  <div class="login fill-height">
    <v-container fluid fill-height class="pa-3 pa-sm-0">
      <v-layout>
        <v-flex xs12 mt-5>
          <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
            <v-card class="elevation-0">
              <div class="d-flex align-center flex-column text-center">
                <div class="login-title text-h4">
                  {{ $t("login.b2b.title") }}
                </div>
                <h3 class="font-weight-regular login-sub ">
                  {{ $t("login.b2b.subtitle") }}
                </h3>
              </div>
              <v-card-text>
                <div class="login-form d-flex flex-column">
                  <h3 class="label mb-2">{{ $t("login.username") }}</h3>
                  <v-text-field
                    v-model="email"
                    class="email-input mb-4"
                    :rules="emailRules"
                    :error-messages="errors"
                    type="email"
                    autocomplete="off"
                    required
                    solo
                    flat
                    filled
                  ></v-text-field>
                  <h3 class="label mb-2">{{ $t("login.password") }}</h3>
                  <v-text-field
                    v-model="password"
                    class="password-input mb-4"
                    autocomplete="off"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="passwordRules"
                    @click:append="toggleShowPassword"
                    required
                    solo
                    flat
                    filled
                  ></v-text-field>
                </div>
                <div
                  class="d-flex align-right justify-center justify-sm-end remember-password"
                >
                  <!-- <v-checkbox
                    color="primary"
                    class="ma-0"
                    hide-details
                    v-model="rememberMe"
                  >
                    <template v-slot:label>
                      <span>{{ $t("login.rememberMe") }}</span>
                    </template>
                  </v-checkbox> -->
                  <div
                    class="recover-pass-wrap text-right"
                    v-if="enableRegistration"
                  >
                    <a @click="goToResetPassword" class="recover-password">
                      {{ $t("login.rememberPassword") }}
                    </a>
                  </div>
                  <!-- <div
                    class="recover-pass-wrap"
                    v-else-if="!$platform_is_cordova"
                  >
                    <a
                      href="https://www.iperal.it/Recupera-Password"
                      class="text-decoration-underline recover-password"
                      >{{ $t("login.rememberPassword") }}</a
                    >
                  </div> -->
                </div>
                <RecaptchaDisclaimer></RecaptchaDisclaimer>

                <ResponseMessage :response="response" />
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-btn
                      :loading="loading"
                      type="submit"
                      class="login-button"
                      x-large
                      width="100%"
                      depressed
                    >
                      {{ $t("login.buttonLogin") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <div
                  class="d-flex flex-column justify-content-center align-center mt-4 register-wrapper"
                  v-if="enableRegistration"
                >
                  <div class="register-label mb-5">
                    <h3>
                      {{ $t("login.messageRegister") }}
                    </h3>
                  </div>
                  <v-btn
                    @click="handleRegister"
                    class="register-button"
                    x-large
                    outlined
                    width="100%"
                    depressed
                  >
                    {{ $t("login.buttonRegister") }}
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ResponseMessage from "@/components/ResponseMessage";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";
import form from "~/mixins/form";
import { mapActions } from "vuex";
import {
  requiredValue,
  isEmail,
  isPasswordMinimumLength
} from "@/validator/validationRules";

export default {
  name: "Login",
  props: {
    hideRegistration: { type: Boolean, required: false, default: false }
  },
  mixins: [form],
  components: { ResponseMessage, RecaptchaDisclaimer },
  data() {
    return {
      loading: null,
      showPassword: false,
      email: "",
      password: "",
      rememberMe: true,
      response: {},
      valid: true,
      lazy: true,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      errors: [],
      invalid: null
    };
  },
  computed: {
    enableRegistration() {
      return global.config.registration;
    }
  },
  methods: {
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart"
    }),
    goToResetPassword() {
      this.$router.push({
        name: "ResetPasswordRequest"
      });
      this.$emit("submit", false);
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async handleSubmit(e) {
      e.preventDefault();
      let token = null;
      let _this = this;
      if (_this.$recaptchaLoaded) {
        await _this.$recaptchaLoaded();
        token = await _this.$recaptcha("login");
      }
      if (_this.password.length > 0) {
        try {
          _this.loading = true;
          _this.response = {};
          await _this.doLogin({
            email: _this.email,
            password: _this.password,
            rememberMe: _this.rememberMe,
            token: token
          });
          // SE AUTENTICATO REGISTRA IL DEVICE PER PUSH NOTIFICATION
          // if (_this.isAuthenticated && global.config.pushNotificationEnabled) {
          //   console.log("IS AUTHENTICATED AND PUSH ENABLED");
          //   _this.registerFirebaseDevice();
          // }
          _this.$emit("submit", true);
        } catch (response) {
          _this.response = response;
        } finally {
          _this.loading = false;
        }
      }
    },
    handleRegister() {
      this.$router.push({
        name: "RegistrationUser",
        path: "/registration-user"
      });
      this.$emit("submit", false);
    }
  }
};
</script>
