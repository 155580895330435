const UPDATED_DRAWER_LINKS = "updatedDrawerLinks";
const UPDATED_DRAWER_SEARCH = "updatedDrawerSearch";

const state = {
  drawerLinks: null,
  drawerSearch: null
};

const actions = {
  [UPDATED_DRAWER_LINKS]({ commit }, value) {
    commit(UPDATED_DRAWER_LINKS, value);
  },
  [UPDATED_DRAWER_SEARCH]({ commit }, value) {
    commit(UPDATED_DRAWER_SEARCH, value);
  }
};

const getters = {};

const mutations = {
  [UPDATED_DRAWER_LINKS]: (state, value) => {
    state.drawerLinks = value;
  },
  [UPDATED_DRAWER_SEARCH]: (state, value) => {
    state.drawerSearch = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
